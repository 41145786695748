function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import $ from "jquery";
import { asArray, hasOwnProperty } from "../utils";
import { isIE } from "../utils/browser";
import { shinyBindAll, shinyInitializeInputs, shinyUnbindAll } from "./initedMethods";
import { sendImageSizeFns } from "./sendImageSize";
import { renderHtml as singletonsRenderHtml } from "./singletons";

function renderDependencies(dependencies) {
  if (dependencies) {
    dependencies.forEach(renderDependency);
  }
} // Render HTML in a DOM element, add dependencies, and bind Shiny
// inputs/outputs. `content` can be null, a string, or an object with
// properties 'html' and 'deps'.


function renderContent(el, content) {
  var where = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "replace";

  if (where === "replace") {
    shinyUnbindAll(el);
  }

  var html = "";
  var dependencies = [];

  if (content === null) {
    html = "";
  } else if (typeof content === "string") {
    html = content;
  } else if (_typeof(content) === "object") {
    html = content.html;
    dependencies = content.deps || [];
  }

  renderHtml(html, el, dependencies, where);
  var scope = el;

  if (where === "replace") {
    shinyInitializeInputs(el);
    shinyBindAll(el);
  } else {
    var $parent = $(el).parent();

    if ($parent.length > 0) {
      scope = $parent;

      if (where === "beforeBegin" || where === "afterEnd") {
        var $grandparent = $parent.parent();
        if ($grandparent.length > 0) scope = $grandparent;
      }
    }

    shinyInitializeInputs(scope);
    shinyBindAll(scope);
  }
} // Render HTML in a DOM element, inserting singletons into head as needed


function renderHtml(html, el, dependencies) {
  var where = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "replace";
  renderDependencies(dependencies);
  return singletonsRenderHtml(html, el, where);
}

var htmlDependencies = {};

function registerDependency(name, version) {
  htmlDependencies[name] = version;
} // Re-render stylesheet(s) if the dependency has specificially requested it
// and it matches an existing dependency (name and version)


function needsRestyle(dep) {
  if (!dep.restyle) {
    return false;
  }

  var names = Object.keys(htmlDependencies);
  var idx = names.indexOf(dep.name);

  if (idx === -1) {
    return false;
  }

  return htmlDependencies[names[idx]] === dep.version;
} // Client-side dependency resolution and rendering


function renderDependency(dep_) {
  var dep = normalizeHtmlDependency(dep_); // Convert stylesheet objs to links early, because if `restyle` is true, we'll
  // pass them through to `addStylesheetsAndRestyle` below.

  var stylesheetLinks = dep.stylesheet.map(function (x) {
    // Add "rel" and "type" fields if not already present.
    if (!hasOwnProperty(x, "rel")) x.rel = "stylesheet";
    if (!hasOwnProperty(x, "type")) x.type = "text/css";
    var link = document.createElement("link");
    Object.entries(x).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          attr = _ref2[0],
          val = _ref2[1];

      if (attr === "href") {
        val = encodeURI(val);
      } // If val isn't truthy (e.g., null), consider it a boolean attribute


      link.setAttribute(attr, val ? val : "");
    });
    return link;
  }); // If a restyle is needed, do that stuff and return. Note that other items
  // (like scripts) aren't added, because they would have been added in a
  // previous run.

  if (needsRestyle(dep)) {
    addStylesheetsAndRestyle(stylesheetLinks);
    return true;
  }

  if (hasOwnProperty(htmlDependencies, dep.name)) return false;
  registerDependency(dep.name, dep.version);
  var $head = $("head").first(); // Add each type of element to the DOM.

  dep.meta.forEach(function (x) {
    var meta = document.createElement("meta");

    for (var _i2 = 0, _Object$entries = Object.entries(x); _i2 < _Object$entries.length; _i2++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i2], 2),
          attr = _Object$entries$_i[0],
          val = _Object$entries$_i[1];

      meta.setAttribute(attr, val);
    }

    $head.append(meta);
  });

  if (stylesheetLinks.length !== 0) {
    $head.append(stylesheetLinks);
  }

  dep.script.forEach(function (x) {
    var script = document.createElement("script");
    Object.entries(x).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          attr = _ref4[0],
          val = _ref4[1];

      if (attr === "src") {
        val = encodeURI(val);
      } // If val isn't truthy (e.g., null), consider it a boolean attribute


      script.setAttribute(attr, val ? val : "");
    });
    $head.append(script);
  });
  dep.attachment.forEach(function (x) {
    var link = $("<link rel='attachment'>").attr("id", dep.name + "-" + x.key + "-attachment").attr("href", encodeURI(x.href));
    $head.append(link);
  });

  if (dep.head) {
    var $newHead = $("<head></head>");
    $newHead.html(dep.head);
    $head.append($newHead.children());
  }

  return true;
}

function addStylesheetsAndRestyle(links) {
  var $head = $("head").first(); // This inline <style> based approach works for IE11

  var refreshStyle = function refreshStyle(href, oldSheet) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", href);

    xhr.onload = function () {
      var id = "shiny_restyle_" + href.split("?restyle")[0].replace(/\W/g, "_");
      var oldStyle = $head.find("style#" + id);
      var newStyle = $("<style>").attr("id", id).html(xhr.responseText);
      $head.append(newStyle); // We can remove the old styles immediately because the new styles
      // should have been applied synchronously.

      oldStyle.remove();
      removeSheet(oldSheet);
      sendImageSizeFns.transitioned();
    };

    xhr.send();
  };

  var findSheet = function findSheet(href) {
    if (!href) return null;

    for (var i = 0; i < document.styleSheets.length; i++) {
      var sheet = document.styleSheets[i]; // The sheet's href is a full URL

      if (typeof sheet.href === "string" && sheet.href.indexOf(href) > -1) {
        return sheet;
      }
    }

    return null;
  }; // Removes the stylesheet from document.styleSheets, and also removes
  // the owning <link> element, if present.


  var removeSheet = function removeSheet(sheet) {
    if (!sheet) return;
    sheet.disabled = true; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore; .cssText doesn't normally exist, but it does on IE?

    if (isIE()) sheet.cssText = "";

    if (sheet.ownerNode instanceof Element) {
      $(sheet.ownerNode).remove();
    }
  };

  links.map(function (link) {
    var $link = $(link); // Find any document.styleSheets that match this link's href
    // so we can remove it after bringing in the new stylesheet

    var oldSheet = findSheet($link.attr("href")); // Add a timestamp to the href to prevent caching

    var href = $link.attr("href") + "?restyle=" + new Date().getTime(); // Use inline <style> approach for IE, otherwise use the more elegant
    // <link> -based approach

    if (isIE()) {
      refreshStyle(href, oldSheet);
    } else {
      $link.attr("href", href); // This part is a bit tricky. The link's onload callback will be
      // invoked after the file is loaded, but it can be _before_ the
      // styles are actually applied. The amount of time it takes for the
      // style to be applied is not predictable. We need to make sure the
      // styles are applied before we send updated size/style information
      // to the server.
      //
      // We do this by adding _another_ link, with CSS content
      // base64-encoded and inlined into the href. We also add a dummy DOM
      // element that the CSS applies to. The dummy CSS includes a
      // transition, and when the `transitionend` event happens, we call
      // sendImageSizeFns.transitioned() and remove the old sheet. We also remove the
      // dummy DOM element and dummy CSS content.
      //
      // The reason this works is because (we assume) that if multiple
      // <link> tags are added, they will be applied in the same order
      // that they are loaded. This seems to be true in the browsers we
      // have tested.
      //
      // Because it is common for multiple stylesheets to arrive close
      // together, but not on exactly the same tick, we call
      // sendImageSizeFns.transitioned(), which is debounced. Otherwise, it can result in
      // the same plot being redrawn multiple times with different
      // styling.

      $link.attr("onload", function () {
        var $dummyEl = $("<div>").css("transition", "0.1s all").css("position", "absolute").css("top", "-1000px").css("left", "0");
        $dummyEl.one("transitionend", function () {
          $dummyEl.remove();
          removeSheet(oldSheet);
          sendImageSizeFns.transitioned();
        });
        $(document.body).append($dummyEl); // To ensure a transition actually happens, change the inline style _after_
        // the DOM element has been added, and also use a new random color each time
        // to prevent any potential caching done by the browser

        var color = "#" + Math.floor(Math.random() * 16777215).toString(16);
        setTimeout(function () {
          return $dummyEl.css("color", color);
        }, 10);
      });
      $head.append(link);
    }
  });
} // Convert legacy HtmlDependency to new HTMLDependency format. This is
// idempotent; new HTMLDependency objects are returned unchanged.


function normalizeHtmlDependency(dep) {
  var _dep$src;

  var hrefPrefix = (_dep$src = dep.src) === null || _dep$src === void 0 ? void 0 : _dep$src.href;
  var result = {
    name: dep.name,
    version: dep.version,
    restyle: dep.restyle,
    meta: [],
    stylesheet: [],
    script: [],
    attachment: [],
    head: dep.head
  };

  if (dep.meta) {
    if (Array.isArray(dep.meta)) {
      // Assume we already have the canonical format:
      //   [{name: "myname", content: "mycontent"}, ...]
      result.meta = dep.meta;
    } else {
      // If here, then we have the legacy format, which we have to convert.
      //   {myname: "mycontent", ...}
      result.meta = Object.entries(dep.meta).map(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
            attr = _ref6[0],
            val = _ref6[1];

        return {
          name: attr,
          content: val
        };
      });
    }
  }

  result.stylesheet = asArray(dep.stylesheet).map(function (s) {
    if (typeof s === "string") {
      s = {
        href: s
      };
    }

    if (hrefPrefix) {
      s.href = hrefPrefix + "/" + s.href;
    }

    return s;
  });
  result.script = asArray(dep.script).map(function (s) {
    if (typeof s === "string") {
      s = {
        src: s
      };
    }

    if (hrefPrefix) {
      s.src = hrefPrefix + "/" + s.src;
    }

    return s;
  }); // dep.attachment might be one of the following types, which we will convert
  // as shown:
  // 0. undefined => []
  // 1. A single string:
  //    "foo.txt"
  //    => [{key: "1", href: "foo.txt"}]
  // 2. An array of strings:
  //    ["foo.txt" ,"bar.dat"]
  //    => [{key: "1", href: "foo.txt"}, {key: "2", href: "bar.dat"}]
  // 3. An object:
  //    {foo: "foo.txt", bar: "bar.dat"}
  //    => [{key: "foo", href: "foo.txt"}, {key: "bar", href: "bar.dat"}]
  // 4. An array of objects:
  //    [{key: "foo", href: "foo.txt"}, {key: "bar", href: "bar.dat"}]
  //    => (Returned unchanged)
  //
  // Note that the first three formats are from legacy code, and the last format
  // is from new code.

  var attachments = dep.attachment; // Convert format 0 (undefined) to format 2 or 4.

  if (!attachments) attachments = []; // Convert format 1 to format 2.

  if (typeof attachments === "string") attachments = [attachments];

  if (Array.isArray(attachments)) {
    // If we've gotten here, the format is either 2 or 4. Even though they are
    // quite different, we can handle them both in the same loop.
    // Need to give TypeScript a bit of help so that it's happy with .map()
    // below. Instead of a union of two array types, tell it it's an array of
    // a union of two types.
    var tmp = attachments; // The contract for attachments is that arrays of attachments are
    // addressed using 1-based indexes. Convert this array to an object.

    attachments = tmp.map(function (attachment, index) {
      if (typeof attachment === "string") {
        return {
          key: (index + 1).toString(),
          href: attachment
        };
      } else {
        return attachment;
      }
    });
  } else {
    // If we got here, it's format 3.
    attachments = Object.entries(attachments).map(function (_ref7) {
      var _ref8 = _slicedToArray(_ref7, 2),
          attr = _ref8[0],
          val = _ref8[1];

      return {
        key: attr,
        href: val
      };
    });
  } // At this point, we've normalized the format to #4. Now we can iterate over
  // it and prepend `hrefPrefix`.


  result.attachment = attachments.map(function (s) {
    if (hrefPrefix) {
      s.href = hrefPrefix + "/" + s.href;
    }

    return s;
  });
  return result;
}

export { renderDependencies, renderContent, renderHtml, registerDependency };